<template>
    <a href="#app" class="scroll-to-top">
        <img :src="require('@/assets/arrow-top.svg')" alt="arrow-top">
    </a>
 </template>

<script>
export default {
    name: "scrollToTop",
}
</script>

<style scoped>

</style>