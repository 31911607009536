<template>
    <section class="intuit-section">
        <div class="intuit-section__img">
            <img :src="require('@/assets/section-13.png')" alt="">
        </div>
        <div class="wrap">
            <div class="intuit-section__block">
                <div>
                    <img :src="require('@/assets/intuit-13.svg')" alt="">
                </div>
                <h2 class="section-title" v-html="title"></h2>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "intuitSection",
    props:{
        object: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        title() {
            return this.object.title[this.getLang]
        },
    }
}
</script>

<style scoped>

</style>