<template>
    <section class="statistic-section">
        <div class="wrap">
           <ul class="statistic-section__list">
               <template v-for="(statistic, i) in statistics" :key="i">
                   <li class="statistic-section__item">
                       <div class="statistic-section__icon">
                           <img :src="require('@/assets/'+statistic.icon+'.svg')" alt="">
                       </div>
                       <div class="statistic-section__value">
                           <h3 class="section-title">{{statistic.count}}+</h3>
                       </div>
                       <div class="statistic-section__text">
                           <p class="section-description">{{statistic.title[getLang]}}</p>
                       </div>
                   </li>
               </template>
           </ul>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "statisticSection",
    props:{
        object: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        statistics() {
            return this.object.list
        },
    }
}
</script>

<style scoped>

</style>