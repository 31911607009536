<template>
    <section class="partners-section">
        <div class="wrap">
            <ul class="partners-section__list">
                <template v-for="(partner, i) in partners" :key="i">
                    <li class="partners-section__item">
                        <a class="partners-section__item-link" :href="partner.url" target="_blank">
                            <img :src="require('@/assets/'+partner.icon+'.svg')" :alt="partner.title">
                        </a>
                    </li>
                </template>
            </ul>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "partnersSection",
    props:{
        object: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        partners() {
            return this.object.list
        },
    }
}
</script>

<style scoped>

</style>