<template>
    <section class="slider-products-section">
        <div class="wrap">
            <swiper class="custom-swiper" v-bind="swiperOptions">
                <swiper-slide class="custom-swiper__slide" v-for="(product, i) in list" :key="i">
                    <div class="custom-swiper__wrap">
                        <div class="custom-swiper__header">
                            <a :href="product.url" target="_blank" class="custom-swiper__link">
                                <img :class="product?.class" class="custom-swiper__icon" :src="require('@/assets/device/'+ product.icon +'.png')" :alt="product.title" :title="product.title">
                            </a>
                        </div>
                        <div class="custom-swiper__footer">
                            <h4 class="custom-swiper__title">{{product.title}}</h4>
                            <p class="custom-swiper__description">{{ product.description }}</p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <!--     navigation     -->
            <div class="custom-swiper-button-prev"></div>
            <div class="custom-swiper-button-next"></div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'
import 'swiper/css/navigation';
import { Autoplay, Navigation} from "swiper/modules";

export default {
    name: "sliderProductsSection",
    props:{
        object: {
            type: Object,
        }
    },
    components:{
        Swiper,
        SwiperSlide,
    },
    data(){
        return{
            swiperOptions:{
                centeredSlides: true,
                slidesPerView: 3,
                loopedSlides: 2,
                spaceBetween: 0,
                autoHeight: false,
                // autoplay: {
                //     delay: 2500,
                //     disableOnInteraction: false,
                // },
                breakpoints:{
                    0:{
                        slidesPerView: 1,
                    },
                    800:{
                        slidesPerView: 2,
                    },
                    1360:{
                        slidesPerView: 3,
                    },
                },
                navigation: {
                    nextEl: '.custom-swiper-button-prev',
                    prevEl: '.custom-swiper-button-next',
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay, Navigation]
            }
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        list() {
            return this.object.list[this.getLang]
        },
    }
}
</script>

<style scoped>

</style>