<template>
    <section class="many-section">
        <div class="wrap">
            <div class="many-section__info-1">
                <h2 class="section-title" v-html="title_1"></h2>
                <div class="section-description" v-html="description_1"></div>
            </div>
            <div class="many-section__info-2">
                <h2 class="section-title" v-html="title_2"></h2>
                <div class="section-description" v-html="description_2"></div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "manyInfo",
    props:{
        object: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        title_1() {
            return this.object.info_1.title[this.getLang]
        },
        description_1() {
            return this.object.info_1.description[this.getLang]
        },
        title_2() {
            return this.object.info_2.title[this.getLang]
        },
        description_2() {
            return this.object.info_2.description[this.getLang]
        },
    }
}
</script>

<style scoped>

</style>