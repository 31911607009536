<template>
    <section class="text-section">
        <div class="wrap">
            <p class="section-description" v-html="description"></p>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "textSection",
    props:{
        object: {
            type: Object,
        }
    },
    data(){
        return{
            list: {
                'Prometheus': '#',
                'Telematixs': '#',
                'Xpower': '#',
                'XCooler': '#',
                //'XRailroad': '#',
                'XRoute': '#',
                'XSchoolPass': '#',
            }
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        description() {
            let message = this.object.description[this.getLang]
            let matches = message.matchAll(/\${([\w]+)}/gi);
            // if(matches.length){
                Array.from(matches).forEach(match => {
                    let link = this.list[match[1]]
                    let str = `<a class="description-link" href="${link}" target="_blank">${match[1]}</a>`
                    message = message.replace(match[0], str)
                })
            // }
            return message
        },
    }
}
</script>

<style scoped>

</style>