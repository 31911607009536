import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App)
app.use(store)
app.use(router)

app.use(VueReCaptcha, {
    siteKey: '6Le5-HcjAAAAAEPmmmk7otSMI8lJS7GTaMyvKkYZ',
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: false,
        explicitRenderParameters: {
            badge: "bottomleft",
        }
    }
})

//app component
import HeaderComponent from "@/components/HeaderComponent.vue";
app.component('HeaderComponent', HeaderComponent);
import FooterComponent from "@/components/FooterComponent.vue";
app.component('FooterComponent', FooterComponent);

//page components
import mainSection from "@/components/mainSection";
app.component('mainSection', mainSection)
import evolutionSection from "@/components/evolutionSection";
app.component('evolutionSection', evolutionSection)
import sliderProductsSection from "@/components/sliderProductsSection";
app.component('sliderProductsSection', sliderProductsSection)
import innovationSection from "@/components/innovationSection";
app.component('innovationSection', innovationSection)
import empoweringSection from "@/components/empoweringSection";
app.component('empoweringSection', empoweringSection)
import hardwareSection from "@/components/hardwareSection";
app.component('hardwareSection', hardwareSection)
import infoSection from "@/components/infoSection";
app.component('infoSection', infoSection)
import supportSection from "@/components/supportSection";
app.component('supportSection', supportSection)
import softwareSection from "@/components/softwareSection";
app.component('softwareSection', softwareSection)
import partnersSection from "@/components/partnersSection";
app.component('partnersSection', partnersSection)
import portfolioSection from "@/components/portfolioSection";
app.component('portfolioSection', portfolioSection)
import textSection from "@/components/textSection";
app.component('textSection', textSection)
import intuitSection from "@/components/intuitSection";
app.component('intuitSection', intuitSection)
import globalReachSection from "@/components/globalReachSection";
app.component('globalReachSection', globalReachSection)
import innovativSection from "@/components/innovativSection";
app.component('innovativSection', innovativSection)
import manyInfo from "@/components/manyInfo";
app.component('manyInfo', manyInfo)
import statisticSection from "@/components/statisticSection";
app.component('statisticSection', statisticSection)
import contactSection from "@/components/contactSection";
app.component('contactSection', contactSection)

//custom components
import HamburgerButton from "@/components/custom/HamburgerButton.vue";
app.component('HamburgerButton', HamburgerButton);
import CustomSelect from "@/components/custom/customSelect.vue";
app.component('CustomSelect', CustomSelect);
import SocialNetwork from "@/components/custom/SocialNetwork.vue";
app.component('SocialNetwork', SocialNetwork);
import contactForm from "@/components/custom/ContactForm";
app.component('contactForm', contactForm);
import scrollToTop from "@/components/custom/scrollToTop";
app.component('scrollToTop', scrollToTop);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
app.use(Toast, {
    "optons": {
        "transition": "Vue-Toastification__bounce",
        "maxToasts": 20,
        "newestOnTop": true,

        "position": "top-right",
        "timeout": 5000,
        "closeOnClick": true,
        "pauseOnFocusLoss": true,
        "pauseOnHover": true,
        "draggable": true,
        "draggablePercent": 0.6,
        "showCloseButtonOnHover": false,
        "hideProgressBar": true,
        "closeButton": "button",
        "icon": true,
        "rtl": false
    }
});

app.mount('#app')
