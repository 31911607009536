<template>
    <section class="main-page">
        <mainSection :object="jsonFile?.mainSection"/>
        <evolutionSection :object="jsonFile?.evolutionSection"/>
        <sliderProductsSection :object="jsonFile?.sliderProductsSection"/>
        <innovationSection :object="jsonFile?.innovationSection"/>
        <empoweringSection :object="jsonFile?.empoweringSection"/>
        <hardwareSection :object="jsonFile?.hardwareSection"/>
        <infoSection :object="jsonFile?.infoSection"/>
        <supportSection :object="jsonFile?.supportSection" :img="'section-8-bg.jpg'"/>
        <softwareSection :object="jsonFile?.softwareSection"/>
        <partnersSection :object="jsonFile?.partnersSection"/>
        <portfolioSection :object="jsonFile?.portfolioSection" :img="'section-11-bg'" v-if="false"/>
        <textSection :object="jsonFile?.textSection"/>
        <intuitSection :object="jsonFile?.intuitSection"/>
        <globalReachSection :object="jsonFile?.globalReachSection"/>
        <innovativSection :object="jsonFile?.innovativSection"/>
        <textSection :object="jsonFile?.textSection2"/>
        <supportSection class="iot-section" :object="jsonFile?.iotSection" :img="'section-17-bg.jpg'"/>
        <manyInfo :object="jsonFile?.manyInfo"/>
        <supportSection class="iot-monitoring-section" :object="jsonFile?.iotMonitoring" :img="'section-19-bg.jpg'" v-if="false"/>
        <portfolioSection class="interception-section" :object="jsonFile?.InterceptionMonitoring" :img="'section-20-bg'" v-if="false"/>
        <statisticSection :object="jsonFile?.statisticSection"/>
        <contactSection :object="jsonFile?.contactSection"/>
    </section>
</template>

<script>
export default {
    name: "mainPage",
    data(){
        return{
            jsonFile: {}
        }
    },
    created() {
        this.jsonFile = require('../../pages/main-page.json')
    }
}
</script>

<style scoped>

</style>