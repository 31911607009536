<template>
    <footer class="footer">
        <div class="wrap" v-if="false">
            <section class="footer__links">
                <a class="footer__logo" href="/">
                    <img :src="require('@/assets/logo.svg')" alt="TURNKEY TRADING">
                </a>
                <div class="footer__links-container">
                    <ul class="footer__links-list">
                        <li v-for="(product, i) in products"
                            :key="i"
                            class="footer__links-item"
                        >
                            <a class="footer__links-link" @click="setLink(product.url)">{{ product.title }}</a>
                        </li>
                    </ul>
                    <SocialNetwork/>
                </div>
            </section>
        </div>
        <section class="footer-copyright">
            <div class="wrap footer-copyright__wrap">
                <div class="footer-copyright__text">
                    Copyright 2023. All right reserved
                </div>
                <ul class="footer-copyright__list">
                    <li v-for="(item, i) in copyrightList"
                        :key="i"
                        class="footer-copyright__item"
                    >
                        <a :href="item.url" target="_blank" class="footer-copyright__link">
                            <span class="footer-copyright__badge" :class="'footer-copyright__badge__'+item.icon"></span>
                            {{ item.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    components: {},
    data() {
        return {
            products: [
                {
                    url: '#main',
                    title: 'Main'
                },
                {
                    url: '#track',
                    title: 'Products'
                },
                {
                    url: '#contacts',
                    title: 'Contacts'
                },
            ],
            copyrightList: [
                {
                    url: 'https://app.termly.io/document/privacy-policy/fbe2ec50-0ea0-4310-a1b3-bc38242ebfca',
                    icon: 'policy',
                    title: 'Privacy Policy'
                },
                {
                    url: 'https://app.termly.io/document/cookie-policy/06a53cd6-29ef-4b8b-bc6d-d8b8702ab2b1',
                    icon: 'cookies',
                    title: 'Cookies Policy'
                },
                {
                    url: 'https://app.termly.io/document/terms-of-service/58980bdd-2b5e-4911-9aa5-5d43ae8c6189',
                    icon: 'conditions',
                    title: 'Terms and Conditions'
                },
            ]
        }
    },
    methods: {
        setLink(link) {
            document.querySelector(link).scrollIntoView({ behavior : "smooth" , block: "center"})
        },
    }
}
</script>

<style scoped>

</style>