<template>
    <section class="innovation-section">
        <div class="innovation-section__bg">
            <img :src="require('@/assets/section-4-3-bg.png')" alt="">
        </div>
        <div class="wrap">
            <h2 class="section-title" v-html="title"></h2>
            <div class="section-description" v-html="description"></div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "innovationSection",
    props:{
        object: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        title() {
            return this.object.title[this.getLang]
        },
        description() {
            return this.object.description[this.getLang]
        },
    }
}
</script>

<style scoped>

</style>