<template>
    <section class="contact-section" id="contacts">
        <div class="wrap">
            <h2 class="section-title">{{title}}</h2>
            <ul class="contact-section__list">
                <template v-for="(item,i) in list" :key="i">
                    <li class="contact-section__item">
                        <div class="contact-section__item-badge">
                            <img :src="require('@/assets/'+item.icon+'.svg')" alt="">
                        </div>
                        <div class="contact-section__item-content">
                            <h4 class="section-description" v-if="item.title[getLang]">
                                {{item.title[getLang]}}:
                            </h4>
                            <p class="section-description" v-for="(info, i) in item.info" :key="i">
                                {{info}}
                            </p>
                        </div>
                    </li>
                </template>
            </ul>
            <div>
                <button class="button" @click="setForm(true)">{{button}}</button>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "contactSection",
    props:{
        object: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
        ]),
        list() {
            return this.object.list
        },
        title(){
            return this.object.title[this.getLang]
        },
        button(){
            return this.object.button[this.getLang]
        },
    },
    methods:{
        ...mapMutations([
            "setForm"
        ])
    }
}
</script>

<style scoped>

</style>